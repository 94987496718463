import { toastr } from "react-redux-toastr";
import { basePaddleUrl, defaultUrl } from "../config/Config";
import { handleAPICall } from "./Axios";
import Cookies from "universal-cookie";
import { errorCodes } from "./errorCodes";

import {
  loginQuery,
  registerQuery,
  emailExistsQuery,
  completeResetPasswordQuery,
  changePasswordQuery,
  sendotpQuery,
  generateOtpQuery,
  logoutQuery,
  createTeamMemberQuery,
  updateTeamMemberQuery,
  getTeamMemberDetailsQuery,
  refreshTokenQuery,
  socketQuery,
  createCustomerQuery,
  getCustomerQuery,
  getProfileQuery,
  createProfileQuery,
  checkDomainsQuery,
  getTldsQuery,
  getNameCheapPricingQuery,
  createDomainQuery,
  getAlertsQuerry,
  getUserDomainsQuerry,
  renewDomainQuerry,
  reactivateDomainQuerry,
  resolveAlertQuerry,
  ToggleDomainPrivacyQuerry,
  setRegistrarStatusLockQuerry,
  getDNSHostsQuery,
  getNameServersQuery,
  createNameServerQuerry,
  updateNameServerQuerry,
  deleteNameServerQuerry,
  setDefaultDNSQuerry,
  setCustomDNSQuerry,
} from "../graphql/authservices/AuthQuery";
import {
  registerMutation,
  loginMutation,
  emailExistsMutation,
  completeResetPasswordMutation,
  changePasswordMutation,
  logoutMutation,
  createTeamMemberMutation,
  refreshTokenMutation,
  createCustomerMutation,
  createProfileMutation,
} from "../graphql/authservices/AuthMutation";
import {
  getProjectQuery,
  createProjectQuery,
  updateProjectQuery,
  getProjectTeamDetailQuery,
  getProjectUnassignedTeamQuery,
  updateAsignedUserQuery,
  createProjectUserQuery,
  deleteUserQuery,
} from "../graphql/dashboardservice/projectservices/ProjectQuery";
import {
  getProjectMutation,
  createProjectMutation,
  updateProjectMutation,
  getProjectTeamDetailMutation,
  getProjectUnassignedTeamMutation,
  updateAssignedUsersaMutation,
  createProjectUserMutation,
  deleteUserMutation,
} from "../graphql/dashboardservice/projectservices/ProjectMutation";
import {
  addManagePermissionQuery,
  updateProfileQuery,
} from "../graphql/managementservice/ManagePermissionQuery";
import {
  addManagePermissionMutation,
  getProfileMutation,
  updateProfileMutation,
} from "../graphql/managementservice/ManagePermissionMutation";
import {
  getAuthenticateWebSocket,
  isLogin,
  isLogout,
  loginAttributes,
  loginAuthorities,
  packageAuthorities,
} from "../redux/slice/login";
import {
  startLoading,
  stopLoading,
  hasError,
  startButtonLoading,
  stopButtonLoading,
  getResponseStatus,
} from "../redux/slice/response";
import {
  createProjectResponse,
  getProjects,
  getTotalProjects,
  startProjectListLoading,
  stopProjectListLoading,
} from "../redux/slice/projects";
import { getProjectTeam } from "../redux/slice/projectTeam";
import { getPermission, profileListResponse } from "../redux/slice/permission";
import { getTeamMember } from "../redux/slice/teamMember";
import { getOrganizationUsers } from "../redux/slice/organizationUsers";
import { dispatch } from "../redux/store";
import {
  allPagesQuery,
  createPageQuery,
  updatePageQuery,
} from "../graphql/workspaceservices/PageQuery";
import {
  createPageMutation,
  getAllPagesMutation,
  updatePageMutation,
} from "../graphql/workspaceservices/PageMutation";
import { createPageResponse, getAllPage } from "../redux/slice/allPages";
import {
  pageHtmlMutation,
  allScreenComponents,
  createScreenDataMutation,
  updateScreenDataMutation,
} from "../graphql/workspaceservices/editorservices/screenMutation";
import {
  createScreenDataQuery,
  getPageHtmlQuery,
  getAllScreenDataQuery,
  updateScreenDataQuery,
} from "../graphql/workspaceservices/editorservices/screenQuery";
import {
  getAllScreenDatas,
  startLoadingScreenData,
  stopLoadingScreenData,
  getScreenDataID,
  getPageComponents,
  getTotalCount,
} from "../redux/slice/allScreenData";
import { getBlockName } from "../redux/slice/workspace";
import { isPageIdExsistInUrl } from "../utils/customMethods";
import {
  getAddressData,
  getAddressId,
  getCountryCodeApiAction,
  getCustomerId,
  getDomainDetailsAction,
  getDomainErrorAction,
  getDomainPricingAction,
  getDomainSubscriptionDataAction,
  getEpochSecondAction,
  getPricesDetailsAction,
  getSubscriptionDataAction,
  getTransactionDetails,
  updateAddressAction,
} from "../redux/slice/paymentFlowSlice";

// this all the function handle difreen type of api using command axios api fuction and difrrent quiry and mutaion

const {
  dashboardEndpoint,
  authEndpoint,
  getCustomerFromOrganizationIdEndpoint,
  getPricesEndpoint,
  getCountryCodeEndpoint,
  changePaymentPopupUrl,

} = defaultUrl;
const cookies = new Cookies();
const cookiesProperties = { path: "/", httpOnly: false, secure: false }; // Set to true if using HTTPS
export const refreshToken = (state) => {
  var calledTimes = localStorage.getItem("calledTimes");
  calledTimes = calledTimes + 1;
  localStorage.setItem("calledTimes", calledTimes);
  const refreshToken = localStorage.getItem("refreshToken");
  const body = {
    ...refreshTokenQuery,
    variables: refreshTokenMutation(refreshToken),
  };
  const handleResponse = (response, state) => {
    const { refreshToken } = response.data.data;
    if (refreshToken !== null) {
      dispatch(isLogin(refreshToken));
      dispatch(loginAttributes(refreshToken.attributes[0]));
      dispatch(packageAuthorities(refreshToken.pkgAuthorities));
      // Set the access token as an HTTP-only cookie
      cookies.set("token", refreshToken.token, { ...cookiesProperties });
      cookies.set("loginAuthorities", refreshToken.authorities, {
        ...cookiesProperties,
      });
      cookies.set("packageAuthorities", refreshToken.pkgAuthorities, {
        ...cookiesProperties,
      });
      cookies.set("loginAttributes", refreshToken.attributes, {
        ...cookiesProperties,
      });
      // Store the new refresh token in memory or a non-HTTP-only cookie
      localStorage.setItem("refreshToken", refreshToken.refreshToken);

      //Call the ongoing API
      if (state.callOngoingAPI) {
        state.func();
      }
    }
  };
  handleAPICall({
    func: () => refreshToken(state),
    callOngoingAPI: false,
    startLoading: () => dispatch(startLoading()),
    endpoint: authEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response, state),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const ssoRedirectUrlService = (sso, sig, id) => {
  const UrlQuery = {
    query:
      `mutation {
      getSsoRedirectUrl(
          sso:"` +
      sso +
      `",
          sig:"` +
      sig +
      `",
          id:"` +
      id +
      `",
        ) 
    }`,
  };

  const body = {
    ...UrlQuery,
  };
  const handleResponse = (response) => {
    const { getSsoRedirectUrl } = response.data.data;
    if (getSsoRedirectUrl !== null || undefined) {
      window.location.replace(getSsoRedirectUrl);
    }
  };
  const handleError = (error) => {
    if (error) {
      dispatch(hasError(error));
      toastr.error("", "Something went wrong !!");
    }
  };
  handleAPICall({
    func: () => ssoRedirectUrlService(sso, sig, id),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => handleError(error),
    stopLoading: () => dispatch(stopLoading("")),
  });
};

export const loginService = (state) => {
  const {
    email,
    password,
    sso,
    sig,
    navigateToResetPassword,
    navigateToPermissionsScreen,
  } = state;
  const body = {
    ...loginQuery,
    variables: loginMutation(email, password, sso, sig),
  };
  const handleResponse = (response) => {
    const { login } = response.data.data;
    console.log("login response.data.data=", login);
    if (login !== null) {
      dispatch(isLogin(login));
      dispatch(loginAuthorities(login.authorities));
      dispatch(loginAttributes(login.attributes));
      dispatch(packageAuthorities(login.pkgAuthorities));
      cookies.set("loginAuthorities", login.authorities, {
        ...cookiesProperties,
      });
      cookies.set("packageAuthorities", login.pkgAuthorities, {
        ...cookiesProperties,
      });
      cookies.set("loginAttributes", login.attributes, {
        ...cookiesProperties,
      });
      const { redirectUrl } = login;
      if (redirectUrl !== null) {
        window.location.replace(redirectUrl);
      }

      // Set the access token as an HTTP-only cookie
      cookies.set("token", login.token, { ...cookiesProperties });
      // Store the new refresh token in memory or a non-HTTP-only cookie
      localStorage.setItem("refreshToken", login.refreshToken);
      localStorage.setItem("userEmail", email);

      toastr.success("", "Logged in  successfully");

      if (login.authorities.length == 0) {
        toastr.error("", "No Permissions added. Please add permissions");
        navigateToPermissionsScreen();
      }
    }
  };
  handleAPICall({
    func: () => loginService(state),
    callOngoingAPI: false,
    navigateTo: navigateToResetPassword,
    startLoading: () => dispatch(startLoading()),
    endpoint: authEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const webSocketAuthService = () => {
  const body = {
    ...socketQuery,
  };
  const handleResponse = (response) => {
    dispatch(
      getAuthenticateWebSocket(response.data.data.authenticateWebSocket)
    );
  };
  handleAPICall({
    func: () => webSocketAuthService(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: authEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const logoutService = () => {
  const token = cookies.get("token") || null;
  const body = {
    ...logoutQuery,
    variables: logoutMutation(token),
  };
  const handleResponse = (response) => {
    const { logoutUserByTokenId } = response.data.data;
    if (logoutUserByTokenId.id) {
      cookies.remove("token", { ...cookiesProperties });
      cookies.remove("loginAuthorities", { ...cookiesProperties });
      cookies.remove("packageAuthorities", { ...cookiesProperties });
      cookies.remove("loginAttributes", { ...cookiesProperties });
      cookies.remove("setCustomerId", { ...cookiesProperties });
      cookies.remove("subscriptionId", { ...cookiesProperties });
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("currentUserName");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("customerIdReceivedFromState");
      localStorage.removeItem("addressIdReceivedFromState");
      localStorage.removeItem("countryCodeFromState");
      localStorage.removeItem("orgUuid");
      localStorage.removeItem("hasRefreshed");
      localStorage.removeItem("AllSubscriptionIds");
      localStorage.removeItem("domainPaymentSuccess");
      // localStorage.removeItem("domainCreationError");

      dispatch(getCustomerId(""));
      dispatch(getAddressId(""));
      dispatch(getAddressData(""));
      dispatch(getSubscriptionDataAction(""));
      dispatch(getDomainDetailsAction(""));
      dispatch(getDomainPricingAction(""));
      dispatch(getTransactionDetails(""));
      dispatch(getEpochSecondAction(""));
      dispatch(isLogout(logoutUserByTokenId.id));
      dispatch(getProjects([]));
      dispatch(getOrganizationUsers([]));
      toastr.success("", "Logout Successfully");
    }
  };
  handleAPICall({
    func: () => logoutService(),
    callOngoingAPI: false,
    startLoading: () => dispatch(startLoading()),
    endpoint: authEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const manualUserLogout = () => {
  cookies.remove("token", { ...cookiesProperties });
  cookies.remove("loginAuthorities", { ...cookiesProperties });
  cookies.remove("packageAuthorities", { ...cookiesProperties });
  cookies.remove("loginAttributes", { ...cookiesProperties });
  cookies.remove("setCustomerId", { ...cookiesProperties });
  cookies.remove("subscriptionId", { ...cookiesProperties });
  localStorage.removeItem("hasRefreshed");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("AllSubscriptionIds");
  localStorage.removeItem("domainPaymentSuccess");
  // localStorage.removeItem("domainCreationError");


  dispatch(isLogout(null));
  toastr.success("", "Logout Successfully");
};

export const generateOtp = (email) => {
  const handleResponse = () => {
    toastr.success("", "Sent OTP on this email");
  };
  handleAPICall({
    func: () => generateOtp(email),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: authEndpoint,
    method: "POST",
    body: generateOtpQuery(email),
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const emailExists = (jsonData, navigateToResetPassword) => {
  const body = {
    ...emailExistsQuery,
    variables: emailExistsMutation(jsonData),
  };
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      const { userEmailExists } = response.data.data;
      if (userEmailExists === false) {
        toastr.error("", "User doesnot exist");
      } else {
        if (navigateToResetPassword) {
          navigateToResetPassword(jsonData);
        }
      }
      dispatch(generateOtp(jsonData.email));
    }
  };
  handleAPICall({
    func: () => emailExists(jsonData),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: authEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const sendOtpToEmail = (email) => {
  const handleResponse = () => {
    generateOtp(email);
  };
  handleAPICall({
    func: () => sendOtpToEmail(email),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: authEndpoint,
    method: "POST",
    body: sendotpQuery(email),
    getResponse: () => handleResponse(),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const completeResetPassword = (data, email, navigateToLogin) => {
  const { otp, newPassword } = data;
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      const { completeResetPassword } = response.data.data;
      if (
        completeResetPassword != null &&
        (completeResetPassword.id !== "" ||
          completeResetPassword.id !== undefined)
      ) {
        toastr.success("", "Password reset successfully");
        navigateToLogin();
      }
    }
  };
  const body = {
    ...completeResetPasswordQuery,
    variables: completeResetPasswordMutation(email, otp, newPassword),
  };
  handleAPICall({
    func: () => completeResetPassword(data, email, navigateToLogin),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: authEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const changePassword = (data) => {
  const { oldPassword, newPassword } = data;
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      const { changePassword } = response.data.data;
      if (
        changePassword != null &&
        (changePassword.id !== "" || changePassword.id !== undefined)
      ) {
        toastr.success("", "Password changed successfully");
      }
    }
  };
  const body = {
    ...changePasswordQuery,
    variables: changePasswordMutation(oldPassword, newPassword),
  };
  handleAPICall({
    func: () => changePassword(data),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: authEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const registerService = (data) => {
  const { firstName, lastName, email, password, otp } = data;
  const handleResponse = (response) => {
    const { register } = response.data.data;
    if (register.id !== "" || register.id !== undefined) {
      toastr.success("", "Register successfully");
      loginService({ email, password });
    }
  };
  const body = {
    ...registerQuery,
    variables: registerMutation(firstName, lastName, email, password, otp),
  };
  handleAPICall({
    func: () => registerService(data),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: authEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const getAllProjects = (count) => {
  const { variables } = getProjectMutation(count);
  const body = {
    ...getProjectQuery,
    variables,
  };
  const handlError = (error) => {
    if (error) {
      dispatch(hasError(error));
    }
  };
  const handleResponse = (response) => {
    dispatch(getProjects(response.data.data.allProjects.edges));
    dispatch(getTotalProjects(response.data.data.allProjects.totalCount));
  };
  handleAPICall({
    func: () => getAllProjects(),
    callOngoingAPI: true,
    calledTimes: 1,
    startLoading: () => dispatch(startProjectListLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => handlError(error),
    stopLoading: () => dispatch(stopProjectListLoading("")),
  });
};

export const createProject = (state) => {
  const { title, description } = state;
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      toastr.success("", "Project Created");
      dispatch(getResponseStatus(response.status));
      dispatch(
        createProjectResponse(
          response.data.data.createProject.project.projectId
        )
      );
      createPage(response.data.data.createProject.project.projectId);
      getAllProjects();
      getAllPages(response.data.data.createProject.project.projectId);
    }
  };
  handleAPICall({
    func: () => createProject(state),
    callOngoingAPI: true,
    startLoading: () => dispatch(startButtonLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body: {
      ...createProjectQuery,
      variables: createProjectMutation(title, description),
    },
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopButtonLoading("")),
  });
};
export const updateProject = (state) => {
  const { title, description, projectId } = state;
  const dataToUpdate = {
    title,
    description,
  };
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      toastr.success("", "Project Updated");
      dispatch(getResponseStatus(response.status));
      getAllProjects();
    }
  };
  handleAPICall({
    func: () => updateProject(state),
    callOngoingAPI: true,
    startLoading: () => dispatch(startButtonLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body: {
      ...updateProjectQuery,
      variables: updateProjectMutation(dataToUpdate, projectId),
    },
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopButtonLoading("")),
  });
};
export const deleteProject = (projectID, isProjectDeleted) => {
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      getAllProjects();
    }
  };
  const dataToUpdate = {
    isDeleted: isProjectDeleted,
  };
  handleAPICall({
    func: () => deleteProject(projectID, isProjectDeleted),
    callOngoingAPI: true,
    endpoint: dashboardEndpoint,
    method: "POST",
    body: {
      ...updateProjectQuery,
      variables: updateProjectMutation(dataToUpdate, projectID),
    },
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
  });
};

export const deactivateProject = (projectID, isProjectDeactivated) => {
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      getAllProjects();
    }
  };
  const dataToUpdate = {
    status: isProjectDeactivated,
  };
  handleAPICall({
    func: () => deactivateProject(projectID, isProjectDeactivated),
    callOngoingAPI: true,
    endpoint: dashboardEndpoint,
    method: "POST",
    body: {
      ...updateProjectQuery,
      variables: updateProjectMutation(dataToUpdate, projectID),
    },
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
  });
};

export const getAllPages = (projectId, navigateToWorkspace, count) => {
  const { variables } = getAllPagesMutation(projectId, count);
  const body = {
    ...allPagesQuery(),
    variables,
  };
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      if (navigateToWorkspace) {
        const page = response.data.data.allPages.edges[0];
        navigateToWorkspace(page, projectId);
      }
      dispatch(getAllPage(response.data.data.allPages.edges));
      dispatch(getTotalCount(response.data.data.allPages.totalCount));
    }
  };
  const handleError = (error) => {
    if (error) {
      dispatch(hasError(error));
      toastr.error("", "Something went wrong !!");
    }
  };
  handleAPICall({
    func: () => getAllPages(projectId, navigateToWorkspace),
    callOngoingAPI: true,
    // startLoading: () => dispatch(startLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    // body: { query: allPagesQuery(projectId) },
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => handleError(error),
    // stopLoading: () => dispatch(stopLoading("")),
  });
};

export const createPage = (
  projectId,
  title,
  add,
  setShowTextField,
  handlePagenavigation
) => {
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      dispatch(getResponseStatus(response.status));
      dispatch(createPageResponse(response.data.data.createPage.page.pageId));
      getAllPages(projectId);
      if (add) {
        add({
          id: response.data.data.createPage.page.id,
          name: title,
        });
        handlePagenavigation(response.data.data.createPage.page.pageId);
      }
      if (setShowTextField) {
        setShowTextField(false);
      }
    }
  };
  const body = {
    ...createPageQuery,
    variables: createPageMutation(projectId, title),
  };
  handleAPICall({
    func: () => createPage(projectId, title),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading("")),
  });
};

export const addManagePermission = (state) => {
  const { username, firstName, lastName, email, profileId, mobile } = state;
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      toastr.success("", "add Manage Permission");
      dispatch(getResponseStatus(response.status));
    }
  };
  handleAPICall({
    func: () => addManagePermission(state),
    callOngoingAPI: true,
    startLoading: () => dispatch(startButtonLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body: {
      ...addManagePermissionQuery,
      variables: addManagePermissionMutation(
        username,
        firstName,
        lastName,
        email,
        profileId,
        mobile
      ),
    },
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopButtonLoading("")),
  });
};

export const getManagePermission = () => {
  const { variables } = getProfileMutation();
  const body = {
    ...getProfileQuery,
    variables,
  };
  const handlError = (error) => {
    if (error) {
      dispatch(hasError(error));
    }
  };
  handleAPICall({
    func: () => getManagePermission(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) =>
      dispatch(getPermission(response.data.data.profiles)),
    hasError: (error) => handlError(error),
    stopLoading: () => dispatch(stopLoading("")),
  });
};

export const updateProfile = (
  profileId,
  profileName,
  addedPermissions,
  removedPermissions
) => {
  const dataToUpdate = {
    profileId,
    profileName,
    addedPermissions,
    removedPermissions,
  };
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      toastr.success("", "Permission updated");
      dispatch(getResponseStatus(response.status));
      getProfileFromQueryFunction();
    }
  };
  handleAPICall({
    func: () =>
      updateProfile(
        profileId,
        profileName,
        addedPermissions,
        removedPermissions
      ),
    callOngoingAPI: true,
    startLoading: () => dispatch(startButtonLoading("")),

    endpoint: dashboardEndpoint,
    method: "POST",
    body: {
      ...updateProfileQuery,
      variables: updateProfileMutation(dataToUpdate),
    },
    getResponse: (response) => {
      handleResponse(response);
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopButtonLoading("")),
  });
};

export const createManagePermission = (state) => {
  const { checkedItems, permissionName } = state;
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      toastr.success("", "create Manage Permission");
      dispatch(getResponseStatus(response.status));
      getAllProjects();
    }
  };
  handleAPICall({
    func: () => createManagePermission(state),
    callOngoingAPI: true,
    startLoading: () => dispatch(startButtonLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body: {
      ...createProfileQuery,
      variables: createProfileMutation(checkedItems, permissionName),
    },
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopButtonLoading("")),
  });
};

export const getProjectTeamDetails = (condition) => {
  const { variables } = getProjectTeamDetailMutation(condition);
  const body = {
    ...getProjectTeamDetailQuery,
    variables,
  };
  const handlError = (error) => {
    if (error) {
      dispatch(hasError(error));
    }
  };
  handleAPICall({
    func: () => getProjectTeamDetails(condition),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) =>
      dispatch(
        getProjectTeam(response.data.data.allProjectUserAssignments.edges)
      ),
    hasError: (error) => handlError(error),
    stopLoading: () => dispatch(stopLoading("")),
  });
};

export const getAllTeamMembers = (showData, condition) => {
  const { variables } = getProjectUnassignedTeamMutation(condition);
  const body = {
    ...getProjectUnassignedTeamQuery,
    variables,
  };
  const handlError = (error) => {
    if (error) {
      dispatch(hasError(error));
    }
  };

  handleAPICall({
    func: () => getAllTeamMembers(showData, condition),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) =>
      showData == 1
        ? dispatch(getOrganizationUsers(response.data.data.users))
        : dispatch(getOrganizationUsers([])),
    hasError: (error) => handlError(error),
    stopLoading: () => dispatch(stopLoading("")),
  });
};

export const unAssignUser = (userObj, condition) => {
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      toastr.success("", "User has been unassigned from this project");
      getProjectTeamDetails(condition);
    }
  };
  handleAPICall({
    func: () => unAssignUser(userObj, condition),
    callOngoingAPI: true,
    endpoint: dashboardEndpoint,
    method: "POST",
    body: {
      ...updateAsignedUserQuery,
      variables: updateAssignedUsersaMutation(userObj),
    },
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
  });
};

export const createProjectUser = (userObj, condition) => {
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      toastr.success("", "User Assigned to this project");
      getProjectTeamDetails(condition);
    }
  };
  handleAPICall({
    func: () => createProjectUser(userObj, condition),
    callOngoingAPI: true,
    startLoading: () => dispatch(startButtonLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body: {
      ...createProjectUserQuery,
      variables: createProjectUserMutation(userObj),
    },
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopButtonLoading("")),
  });
};

export const deleteUser = (userID) => {
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      const { deleteUser } = response.data.data;
      if (deleteUser.id !== "" || deleteUser.id !== undefined) {
        toastr.success("User Deleted Successfully");
        dispatch(getResponseStatus(response.status));
        getAllTeamMembers(1, {});
      }
    }
  };
  handleAPICall({
    func: () => deleteUser(userID),
    callOngoingAPI: true,
    endpoint: dashboardEndpoint,
    method: "POST",
    body: {
      ...deleteUserQuery,
      variables: deleteUserMutation(userID),
    },
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
  });
};

export const getHtmlOfPage = (pageUID) => {
  const { variables } = pageHtmlMutation(pageUID);
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      dispatch(getResponseStatus(response.status));
      dispatch(getPageComponents(response?.data?.data.getComponents));
    }
  };
  const body = {
    ...getPageHtmlQuery,
    variables,
  };
  handleAPICall({
    func: () => getHtmlOfPage(pageUID),
    callOngoingAPI: true,
    // startLoading: () => dispatch(startLoadingScreenData("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    // stopLoading: () => dispatch(stopLoadingScreenData("")),
  });
};
export const getAllScreeData = (index) => {
  const response = allScreenComponents(index);
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      dispatch(getResponseStatus(response.status));
      dispatch(getAllScreenDatas(response?.data?.data?.allScreenData?.edges));
      dispatch(getTotalCount(response?.data?.data?.allScreenData?.totalCount));
    }
  };
  const body = {
    ...getAllScreenDataQuery,
    variables: { ...response },
  };
  handleAPICall({
    func: () => getAllScreeData(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoadingScreenData("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoadingScreenData("")),
  });
};

export const createScreenData = (
  name,
  jsonData,
  pageUuid,
  projectUuid,
  pageIdInt,
  editor
) => {
  const handleResponse = (response) => {
    if (
      response.status === errorCodes.OK.code &&
      response.data.errors === undefined
    ) {
      dispatch(getResponseStatus(response.status));

      getAllScreeData();

      const allBlocks = editor?.BlockManager?.getAll();
      const existingBlock = allBlocks.find((item) => item.getLabel() === name);
      existingBlock.set(
        "id",
        response?.data?.data?.createScreenDatum?.screenDatum.id
      );
      if (isPageIdExsistInUrl()) {
        dispatch(getBlockName(null));
      }
    } else if (response.data.errors) {
      toastr.warning("", "somthing went wrong");
    }
  };
  const body = {
    ...createScreenDataQuery,
    variables: createScreenDataMutation(
      name,
      jsonData,
      pageUuid,
      projectUuid,
      pageIdInt
    ),
  };
  handleAPICall({
    func: () => createScreenData(jsonData, pageUuid, projectUuid, pageIdInt),
    callOngoingAPI: true,
    startLoading,
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading,
  });
};

export const createTeamMember = (memberData) => {
  const response = createTeamMemberMutation(memberData);

  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      toastr.success("", "User Added Successfully");
      dispatch(getResponseStatus(response.status));
      getAllTeamMembers(1, {});
    }
  };
  const body = {
    ...createTeamMemberQuery,
    variables: { ...response },
  };
  handleAPICall({
    func: () => createTeamMember(memberData),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading("")),
  });
};

export const updateTeamMember = (jsonData) => {
  const handleResponse = (response) => {
    if (response.status === errorCodes.OK.code) {
      toastr.success("", "User Details Updated Successfully");
      dispatch(getResponseStatus(response.status));
      getAllTeamMembers(1, {});
    }
  };
  const body = {
    ...updateTeamMemberQuery(jsonData),
  };
  handleAPICall({
    func: () => updateTeamMember(jsonData),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) => {
      handleResponse(response);
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading("")),
  });
};

export const getTeamMemberDetails = (id) => {
  handleAPICall({
    func: () => getTeamMemberDetails(id),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: authEndpoint,
    method: "POST",
    body: getTeamMemberDetailsQuery(id),
    getResponse: (response) =>
      dispatch(getTeamMember(response.data.data.userById)),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const getProjectUnassignedTeamList = (showData, condition) => {
  const { variables } = getProjectUnassignedTeamMutation(condition);
  const body = {
    ...getProjectUnassignedTeamQuery,
    variables,
  };
  const handlError = (error) => {
    if (error) {
      dispatch(hasError(error));
    }
  };

  handleAPICall({
    func: () => getProjectUnassignedTeamList(showData, condition),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) =>
      showData == 1
        ? dispatch(getOrganizationUsers(response.data.data.users))
        : dispatch(getOrganizationUsers([])),
    hasError: (error) => handlError(error),
    stopLoading: () => dispatch(stopLoading("")),
  });
};

export const getPermissions = () => {
  const { variables } = getProfileMutation();
  const body = {
    ...getProfileQuery,
    variables,
  };
  const handlError = (error) => {
    if (error) {
      dispatch(hasError(error));
    }
  };
  handleAPICall({
    func: () => getPermissions(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading("")),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) =>
      dispatch(getPermission(response.data.data.profiles)),
    hasError: (error) => handlError(error),
    stopLoading: () => dispatch(stopLoading("")),
  });
};

export const updateScreenDataByID = (elementId, jsonData) => {
  const handleResponse = (response) => {
    if (response.status === 200 && response.data.errors === undefined) {
      if (isPageIdExsistInUrl()) {
        dispatch(getBlockName(null));
      }
      dispatch(getScreenDataID(null));
    } else if (response.data.errors) {
      toastr.warning("", "somthing went wrong");
    }
  };
  const body = {
    ...updateScreenDataQuery,
    variables: updateScreenDataMutation(elementId, jsonData),
  };
  handleAPICall({
    func: () => updateScreenDataByID(elementId, jsonData),
    callOngoingAPI: true,
    startLoading,
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading,
  });
};

export const updatePage = (title, pageId, projectId, clearInputValue) => {
  const handleResponse = (response) => {
    if (
      response.status === errorCodes.OK.code &&
      response.data.errors === undefined
    ) {
      getAllPages(projectId);
      clearInputValue(true);
    }
    if (response.data.errors) {
      clearInputValue(false);
      toastr.error("", "Something went wrong !!");
    }
  };
  const body = {
    ...updatePageQuery,
    variables: updatePageMutation(title, pageId),
  };
  handleAPICall({
    func: () => updatePage(title, pageId),
    callOngoingAPI: true,
    endpoint: dashboardEndpoint,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
  });
};

export const getCurrencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case "USD":
      currencyCode = "$";
      break;
    case "EUR":
      currencyCode = "€";
      break;
    case "INR":
      currencyCode = "₹";
      break;
    case "RUB":
      currencyCode = "₽";
      break;
    case "JPY":
      currencyCode = "¥";
      break;
    default:
      currencyCode = "$";
  }
  localStorage.setItem("currencySymbol", currencyCode);
  return currencyCode;
};

export const createCustomerFunction = () => {
  const email = localStorage.getItem("userEmail");
  const customerName = localStorage.getItem("currentUserName");
  const body = {
    ...createCustomerQuery,
    variables: createCustomerMutation(email, customerName),
  };
  const handleError = (error) => {
    if (error) {
      dispatch(hasError(error));
    }
  };
  handleAPICall({
    func: () => createCustomerFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: dashboardEndpoint,
    method: "POST",
    body,

    getResponse: (response) => {
      if (response.data.data.createCustomer.customerId) {
        dispatch(getCustomerId(response.data.data.createCustomer.customerId));

        localStorage.setItem(
          "customerIdReceivedFromState",
          response.data.data.createCustomer.customerId
        );
      } else {
        console.error("Customer ID is not present in the response");
      }
    },

    hasError: (error) => handleError(error),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const getCustomerFromQueryFunction = () => {
  const body = {
    ...getCustomerQuery,
  };
  handleAPICall({
    func: () => getCustomerFromQueryFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: authEndpoint,
    body,
    method: "POST",
    getResponse: (response) => {
      if (response.data.data.getCustomer.customerId) {
        dispatch(getCustomerId(response.data.data.getCustomer.customerId));
        cookies.set(
          "setCustomerId",
          response.data.data.getCustomer.customerId,
          { ...cookiesProperties }
        );
        localStorage.setItem(
          "customerIdReceivedFromState",
          response.data.data.getCustomer.customerId
        );
      } else {
        console.error("Customer ID is not present in the response");
      }
    },

    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const getCustomerFromOrganizationIdFunction = () => {
  handleAPICall({
    func: () => getCustomerFromOrganizationIdFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: getCustomerFromOrganizationIdEndpoint,
    method: "GET",
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const createProfileFromQueryFunction = () => {
  const profileName = localStorage.getItem("profileTitle");
  const permissionIds = localStorage.getItem("profileIds").split(",");

  const body = {
    ...createProfileQuery,
    variables: createProfileMutation(profileName, permissionIds),
  };
  handleAPICall({
    func: () => createProfileFromQueryFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startButtonLoading("")),
    endpoint: authEndpoint,
    body,
    method: "POST",
    getResponse: (response) => {
      if (response.status === errorCodes.OK.code) {
        toastr.success("", "New Profile Created");
        getProfileFromQueryFunction();
      }
    },

    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopButtonLoading("")),
  });
};
export const getProfileFromQueryFunction = () => {
  const body = {
    ...getProfileQuery,
  };
  handleAPICall({
    func: () => getProfileFromQueryFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startButtonLoading("")),
    endpoint: authEndpoint,
    body,
    method: "POST",
    getResponse: (response) => {
      dispatch(profileListResponse(response.data.data.profiles));
    },

    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopButtonLoading("")),
  });
};
export const getTransactionDataFunction = (subscription_id) => {
  handleAPICall({
    func: () => getTransactionDataFunction(),
    callOngoingAPI: true,
    // startLoading: () => dispatch(startLoading()),
    endpoint: `${basePaddleUrl(
      `transactions/latest?subscription_id=${subscription_id}`
    )}`,
    method: "GET",
    getResponse: (response) => {
      dispatch(getTransactionDetails(response.data));
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const getAddressFunction = () => {
  handleAPICall({
    func: () => getAddressFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: `${basePaddleUrl(
      `customers/${localStorage.getItem(
        "customerIdReceivedFromState"
      )}/address/${localStorage.getItem("addressIdReceivedFromState")}`
    )}`,
    method: "GET",
    getResponse: (response) => {
      dispatch(getAddressData(response.data));
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const getAddressIdFunction = () => {
  handleAPICall({
    func: () => getAddressIdFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: `${basePaddleUrl(
      `customers/${localStorage.getItem(
        "customerIdReceivedFromState"
      )}/addresses`
    )}`,
    method: "GET",
    getResponse: (response) => {
      dispatch(getAddressId(response.data[0].address_id));
      localStorage.setItem(
        "addressIdReceivedFromState",
        response.data[0].address_id
      );
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const postAddressFunction = (formData, onSave) => {
  const body = {
    ...formData,
  };
  const handleResponse = (response) => {
    dispatch(getAddressId(response.data.address_id));
    localStorage.setItem(
      "addressIdReceivedFromState",
      response.data.address_id
    );
    onSave();
  };
  handleAPICall({
    func: () => postAddressFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: `${basePaddleUrl(
      `customers/${localStorage.getItem("customerIdReceivedFromState")}/address`
    )}`,
    method: "POST",
    body,
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const updateAddressFunction = (addressData) => {
  const body = {
    ...addressData,
  };
  handleAPICall({
    func: () => updateAddressFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: `${basePaddleUrl(
      `customers/${localStorage.getItem(
        "customerIdReceivedFromState"
      )}/address/${localStorage.getItem("addressIdReceivedFromState")}`
    )}`,
    method: "PUT",
    body,
    getResponse: (response) => {
      dispatch(updateAddressAction(response.data));
      {
        response.status === 200
          ? toastr.success("Address Changed successfully")
          : toastr.error("Server error, Please try again");
      }
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const getPricesDetailsFunction = () => {
  handleAPICall({
    func: () => getPricesDetailsFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: getPricesEndpoint,
    method: "GET",
    getResponse: (response) => dispatch(getPricesDetailsAction(response.data)),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const getCountryCodeFunction = () => {
  const handleResponse = (response) => {
    dispatch(getCountryCodeApiAction(response.data.countryCode));
    localStorage.setItem("countryCodeFromState", response.data.countryCode);
    localStorage.setItem("countryData", JSON.stringify(response.data));
  };
  handleAPICall({
    func: () => getCountryCodeFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: getCountryCodeEndpoint,
    method: "GET",
    getResponse: (response) => handleResponse(response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const getSubscriptionDataFunction = () => {
  handleAPICall({
    func: () => getSubscriptionDataFunction(),
    callOngoingAPI: true,
    // startLoading: () => dispatch(startLoading()),
    // endpoint: getSubscriptionLatest,
    endpoint: `${basePaddleUrl(
      `subscriptions/latest?customer_id=${localStorage.getItem("customerIdReceivedFromState")}`
    )}`,
    method: "GET",
    getResponse: (response) => {
      dispatch(getSubscriptionDataAction(response.data));
      cookies.set("subscriptionId", response.data.subscription_id, {
        ...cookiesProperties,
      });

      console.log("status of sub", response.data);
      if (response.data.status === "canceled") {
        refreshToken();
      }
      if (response.data.status === "past_due") {
        refreshToken();
      }
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const getDomainSubscriptionFunction = () => {
  handleAPICall({
    func: () => getDomainSubscriptionFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    // endpoint: getSubscriptionLatest,
    endpoint: `${basePaddleUrl(
      `subscriptions/latest?customer_id=${localStorage.getItem("customerIdReceivedFromState")}`
    )}`,
    method: "GET",
    getResponse: (response) => {
      dispatch(getSubscriptionDataAction(response.data));
            dispatch(getDomainSubscriptionDataAction(response.data));
            
            localStorage.setItem("fetchedFlag","true")
            localStorage.removeItem("domainCreationError");
            localStorage.setItem("doaminSubscriptionId",response.data.subscription_id);
     

      console.log("status of domain sub in action", response.data);
      console.log("doaminSubscriptionId in action ls",localStorage.getItem("doaminSubscriptionId"))
    
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const getAllSubscriptionDataFunction = () => {
  handleAPICall({
    func: () => getAllSubscriptionDataFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    // endpoint: getSubscriptionLatest,
    endpoint: `${basePaddleUrl(
      `subscriptions?customer_id=${localStorage.getItem("customerIdReceivedFromState")}`
    )}`,

    method: "GET",
    getResponse: (response) => {
      // dispatch(getSubscriptionDataAction(response.data));
      // cookies.set("allSubscriptionId", response.data.subscription_id, {
      //   ...cookiesProperties,
      // });

      console.log("status of all sub", response.data);
      localStorage.setItem("AllSubscriptionIds", JSON.stringify(response.data));
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const changeBillingCycleDateFunction = (
  subscription_id,
  epochSecondTime
) => {
  const body = {
    next_billed_at: epochSecondTime,
    proration_billing_mode: "prorated_immediately",
  };
  const sub_id = `${basePaddleUrl(
    `subscriptions/${subscription_id}/updateBilling`
  )}`;
  handleAPICall({
    func: () => changeBillingCycleDateFunction(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: sub_id,
    method: "PUT",
    body,
    getResponse: (response) =>
      console.log("change billing date response=", response),
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const changePaymentMethodFucntion = (subscription_id, paddle) => {
  handleAPICall({
    func: () => changePaymentMethodFucntion(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: `${basePaddleUrl(
      `transactions/${subscription_id}/updatePaymentMethod`
    )}`,
    method: "GET",
    getResponse: (response) => {
      if (paddle) {
        paddle.Checkout.open({
          settings: {
            theme: "light",
            locale: "en",
          },
          checkout: {
            url: changePaymentPopupUrl,
          },
          transactionId: response.data.transaction_id,
        });
      }
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const refreshPaymentMethodFucntion = (
  subscription_id,
  setNextBilledDate
) => {
  handleAPICall({
    func: () => refreshPaymentMethodFucntion(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: `${basePaddleUrl(`subscriptions/${subscription_id}`)}`,
    method: "GET",
    getResponse: (response) => {
      setNextBilledDate(response.data.next_billed_at);
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const cancelSubscription = (subscription_id) => {
  handleAPICall({
    func: () => cancelSubscription(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: `${basePaddleUrl(`subscriptions/${subscription_id}/cancel?effectiveFrom=next_billing_period`)}`,

    method: "POST",
    getResponse: (response) => {
      if (response.status === 200) {
        toastr.success("You have cancelled your Subscription");
        setTimeout(() => {
          getSubscriptionDataFunction();
        }, 1500);
      } else {
        toastr.error("Server error, pleae try again");
      }
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const undoCancelSubscription = (subscription_id) => {
  handleAPICall({
    func: () => undoCancelSubscription(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: `${basePaddleUrl(`subscriptions/${subscription_id}/cancel/rollback`)}`,

    method: "PUT",
    getResponse: (response) => {
      if (response.status === 200) {
        toastr.success("Your Subscription is active");
        setTimeout(() => {
          getSubscriptionDataFunction();
        }, 1500);
      } else {
        toastr.error("Server error, pleae try again");
      }
    },
    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const checkDomains = (enteredDomainName, domainExtension) => {
  const body = {
    ...checkDomainsQuery(enteredDomainName),
  };
  console.log("domain extension inside action", domainExtension);
  handleAPICall({
    func: () => checkDomains(enteredDomainName),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log(
        "Check domian graph ql api is",
        response.data.data.checkDomains[0]
      );
      dispatch(getDomainDetailsAction(response.data.data.checkDomains[0]));
      if (response) {
        getNameCheapPricing(domainExtension);
      }
    },

    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const getNameCheapPricing = (domainExtension) => {
  const body = {
    ...getNameCheapPricingQuery(domainExtension),
  };
  handleAPICall({
    func: () => getNameCheapPricing(domainExtension),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log(
        "get pricing of domain from graph ql api is",
        response.data.data.getTldPricing
      );
      dispatch(getDomainPricingAction(response.data.data.getTldPricing));

      // dispatch(getDomainDetailsAction(response.data.data.checkDomains[0]));
    },

    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const renewDomain=(subscription_id,organisationId,domainName, numberOfYears,isPremiumDomain,premiumPrice)=>{
  const body={
    ...renewDomainQuerry(subscription_id,organisationId,domainName, numberOfYears,isPremiumDomain,premiumPrice),
  }
  handleAPICall({
    func: () => renewDomain(subscription_id,organisationId,domainName, numberOfYears,isPremiumDomain,premiumPrice),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log(
        "Renew Domain response is:",
        response.data
      );
    },

    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
}
export const reactivateDomain=(subscription_id,organisationId,domainName, numberOfYears,isPremiumDomain,premiumPrice)=>{
  const body={
    ...reactivateDomainQuerry(subscription_id,organisationId,domainName, numberOfYears,isPremiumDomain,premiumPrice),
  }
  handleAPICall({
    func: () => reactivateDomain(subscription_id,organisationId,domainName, numberOfYears,isPremiumDomain,premiumPrice),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log(
        "ReActivate Domain response is:",
        response.data
      );
    },

    hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
}
export const getTld = () => {
  const body = {
    ...getTldsQuery,
  };
  handleAPICall({
    func: () => getTld(),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log("get tld list from graph ql api is", response.data);
      // dispatch(getDomainDetailsAction(response.data.data.checkDomains[0]));
    },
hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
};
export const getAlerts=(oraganisationId,resolve)=>{
  const body={
    ...getAlertsQuerry(oraganisationId,resolve),
  };
  handleAPICall({
    func: () => getAlerts(oraganisationId,resolve),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log("get domain Alerts", response.data);
      // dispatch(getDomainDetailsAction(response.data.data.checkDomains[0]));
    },
hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
  }

  export const resolveAlert=(uuid,resolvedBy)=>{
    const body={
      ...resolveAlertQuerry(uuid,resolvedBy),
    };
    handleAPICall({
      func: () => resolveAlert(uuid,resolvedBy),
      callOngoingAPI: true,
      startLoading: () => dispatch(startLoading()),
      endpoint: "https://domainmgmt.synsta.io/graphql",
      body,
      method: "POST",
      getResponse: (response) => {
        console.log("get domain Resolved Alerts", response.data);
      },
  hasError: (error) => dispatch(hasError(error)),
      stopLoading: () => dispatch(stopLoading()),
    });

  }
  export const getUserDomains=(oraganisationId)=>{
    const body={
      ...getUserDomainsQuerry(oraganisationId),
    }
    handleAPICall({
      func: () => getUserDomains(oraganisationId),
      callOngoingAPI: true,
      startLoading: () => dispatch(startLoading()),
      endpoint: "https://domainmgmt.synsta.io/graphql",
      body,
      method: "POST",
      getResponse: (response) => {
        console.log("get user domain ", response.data.data.getUserDomains);
        localStorage.setItem(
          "getUserDomains",
          JSON.stringify(response.data.data.getUserDomains)
        );        // dispatch(getDomainDetailsAction(response.data.data.checkDomains[0]));
      },
  hasError: (error) => dispatch(hasError(error)),
      stopLoading: () => dispatch(stopLoading()),
    });
  }
export const createDomain = (
  creatDoaminFormData,
  selectedCountryNameCode,
  selectedCountry,
  subscription_id,
  checkedWhoIsGuard,
  formattedExtendedAttributes,
) => {
  console.log("crreate DOmian FornmDtata data ", creatDoaminFormData);
  console.log(" sub id in crreate DOmian acgtion  ", subscription_id);

  const body = {
    ...createDomainQuery(
      creatDoaminFormData,
      selectedCountryNameCode,
      selectedCountry,
      subscription_id,
      checkedWhoIsGuard,
      formattedExtendedAttributes,
    ),
  };
  handleAPICall({
    func: () =>
      createDomain(
        creatDoaminFormData,
        selectedCountryNameCode,
        selectedCountry,
        subscription_id,
      checkedWhoIsGuard,
      formattedExtendedAttributes,
      ),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log("create Domain response", response.data);
      if (response.status === errorCodes.OK.code) {
        toastr.success("", "Domain Created successfully");
        console.log("domain created response", response);
      }
      else{
        console.log("error in   domain craetion",response)
        console.log("error in   domain craetion")
      }
      // dispatch(getDomainPricingAction(response.data.data.getTldPricing));

      // dispatch(getDomainDetailsAction(response.data.data.checkDomains[0]));
    },

    // hasError: (error) => dispatch(hasError(error)),
    hasError: (error) => {
      console.log("Error occurred:", error);
      localStorage.setItem("domainCreationError",error);
      dispatch(hasError(error));
      dispatch(getDomainErrorAction(error));

    },
    
    stopLoading: () => dispatch(stopLoading()),
  });
};

export const ToggleDomainPrivacy=(forwardedToEmail,
  toggle,
  whoisGuardID)=>{
  const body={
    ...ToggleDomainPrivacyQuerry(forwardedToEmail,
      toggle,
      whoisGuardID,),
  }
  handleAPICall({
    func: () => ToggleDomainPrivacy(forwardedToEmail,
      toggle,
      whoisGuardID,),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log("toggle domain response ", response.data);
      if(response.data.data.toggleDomainPrivacy.whoisGuardEnabled===true)
      {
        toastr.success("Domain Privacy protection is ON")
      }
      else
      { toastr.warning("Domain Privacy protection is OFF")

      }
      // dispatch(getDomainDetailsAction(response.data.data.checkDomains[0]));
    },
hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
}

export const setRegistrarStatusLock=(domainName,lock)=>{
  const body={
    ...setRegistrarStatusLockQuerry(domainName,lock),
  }
  handleAPICall({
    func: () => setRegistrarStatusLock(domainName,lock),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log("set registrar lock status response ", response.data);
      if(response.data.data.setRegistrarStatusLock.registrarLockStatus===true)
      {
        toastr.success("Your domain is locked")
      }
      else
      { toastr.warning("Your domain is unlocked,")

      }
    },
hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
}

export const getDNSHosts=(domainName)=>{
  const body={
    ...getDNSHostsQuery(domainName),
  }
  handleAPICall({
    func: () => getDNSHosts(domainName),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log("get dns hosts  response ", response.data);
      
    },
hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
}

export const getNameServer=(domainName)=>{
  const body={
    ...getNameServersQuery(domainName),
  }
  handleAPICall({
    func: () => getNameServer(domainName),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log("get dns name server  response ", response.data);
      localStorage.setItem("getNameServerData",JSON.stringify(response.data))
      
    },
hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
}

export const createNameServer=(tld,sld,nameServer, ip)=>{
  const body={
    ...createNameServerQuerry(tld,sld,nameServer,ip),
  }
  handleAPICall({
    func: () => createNameServer(tld,sld,nameServer,ip),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log("get dns name server  response ", response.data);
      
    },
hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
}
export const updateNameServer=(tld,sld,nameServer,oldIp, ip)=>{
  const body={
    ...updateNameServerQuerry(tld,sld,nameServer,oldIp, ip),
  }
  handleAPICall({
    func: () => updateNameServer(tld,sld,nameServer,oldIp,ip),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log("update name server response ", response.data);
      
    },
hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
}
export const deleteNameServer=(tld,sld,nameServer)=>{
  const body={
    ...deleteNameServerQuerry(tld,sld,nameServer),
  }
  handleAPICall({
    func: () => deleteNameServer(tld,sld,nameServer),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log("delete name server response ", response.data);
      
    },
hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
}

export const setDefaultDNS=(sld,tld)=>{
  const body={
    ...setDefaultDNSQuerry(sld,tld),
  }
  handleAPICall({
    func: () => setDefaultDNS(sld,tld),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log("set Default dns response ", response.data);
      
    },
hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
} 
export const setCustomDNS=(sld,tld,nameServers)=>{
  const body={
    ...setCustomDNSQuerry(sld,tld,nameServers),
  }
  handleAPICall({
    func: () => setCustomDNS(sld,tld,nameServers),
    callOngoingAPI: true,
    startLoading: () => dispatch(startLoading()),
    endpoint: "https://domainmgmt.synsta.io/graphql",
    body,
    method: "POST",
    getResponse: (response) => {
      console.log("set Custom dns response ", response.data);
      
    },
hasError: (error) => dispatch(hasError(error)),
    stopLoading: () => dispatch(stopLoading()),
  });
} 