import Cookies from "universal-cookie";

/* eslint-disable */
export const defaultStyle = {
  buttonColor: "#ec673d",
  buttonTextColor: "White",
  cursorColor: "#FFA600",
  secondaryColor: "#ec673d",
  secondaryHoverColor: "#ef7e5a",
  defaultColor: "#000000",
  errorBgcolor: "#FF4842",
  primaryBackGround: "#f3f3f3",
  whiteBackground: "#ffffff",
  avatarHeight: "30px",
  avatarWidth: "30px",
  synstaoOrangeColor: "#ec673d",
  synstaoOrangeLightColor: "#ec673d12",
  blockLabelColor: "#5F5F5F",
  blackColorLight: "#1a1a1a",
  cancelBlack: "#09090A",
  cancelBackgroundHover: "#eeeeee",
  textFieldGreyBorder: "#7e7e7e",
  lightBlackColor: "#999999",
};

export const dashboardDisplay = {
  direction: {
    xs: "column",
    sm: "row",
    md: "row",
  },
  gap: {
    xs: 1,
  },
  alignItems: {
    xs: "start",
    sm: "center",
    md: "center",
  },
};

export const defaultButtonStyle = {
  color: defaultStyle.buttonTextColor,
  bgcolor: defaultStyle.buttonColor,
  borderColor: defaultStyle.buttonColor,
  borderRadius: "5px",
  ":hover": {
    borderColor: defaultStyle.buttonColor,
    backgroundColor: defaultStyle.secondaryHoverColor,
  },
};

export const addNewButtonStyle = {
  fontSize: "0.75rem",
  height: "2.125rem",
  width: "auto",
  padding: "10px 14px 10px 12px",
};

export const defaultChatBoxStyle = {
  color: "black",
  borderRadius: 3,
  borderColor: "black",
  padding: "2px",
  margin: "2px !important",
  width: "100px",
};
const cookies = new Cookies();
const addressIdReceived = localStorage.getItem("addressIdReceivedFromState");
const customerIdReceived = cookies.get("setCustomerId");
const organization_id = "test5";
export const basePaddleUrl = (paddleUrl) =>
  `https://subscription.synsta.io/api/paddle/${paddleUrl}`;
export const defaultUrl = {
  dashboardEndpoint: process.env.REACT_APP_API_URL,
  authEndpoint: process.env.REACT_APP_API_URL,
  paddleToken: "test_23ec817d37f6c7228c184d51bd0",
  postCustomerEndpoint: `${basePaddleUrl(`customers`)}`,

  updateAddressEndpoint: `${basePaddleUrl(
    `customers/${customerIdReceived}/address/${addressIdReceived}`
  )}`,
  postAddressEndpoint: `${basePaddleUrl(
    `customers/${customerIdReceived}/address`
  )}`,

  getPricesEndpoint: `${basePaddleUrl(`products/all`)}`,
  getCountryCodeEndpoint: "https://freeipapi.com/api/json",
  getsubscriptionEndpoint: `${basePaddleUrl(`subscriptions/latest?customer_id=${customerIdReceived}`)}`,
  changePaymentPopupUrl: "https://craft.synsta.io/dashboard",
  getSubscriptionLatest:`https://subscription.synsta.io/api/paddle/subscriptions/latest?customer_id=${cookies.get("setCustomerId")}`,
  ApIkey: "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",
};
export const ToastBarStyle = {
  width: "100%",
  height: "2.5rem",
  border: "1px solid #ec673d",
  background: "#FCE5DE",
  borderRadius: "4px",
  boxShadow: "4px 4px 12px rgba(236, 103, 61, 0.25)",
};
export const styleBlackBorder = {
  "& .MuiInputBase-root.Mui-focused fieldset": {
    border: `1px solid ${defaultStyle.defaultColor}`,
  },
  "& .MuiInputBase-root:not(.Mui-focused) fieldset": {
    border: `1px solid ${defaultStyle.textFieldGreyBorder}`,
  },
};
export const workspace = {
  background: "#3F3F46",
  backgroundColor: "#706f6f",
  backgroundColor2: "#444",
  color: "white",
  pointerCursor: "pointer",
  width: "100%",
  height: "80%",
  flexDisplay: "flex",
  opacity: 1,
  padding: "5px",
  borderRadius: "2px",
  ButtonTextColor: "White",
  SecondryColor: "#ff5745",
  SecondryHoverColor: "#3733f0",
  textRed: "Red",
  textGrey: "Grey",
  primaryColor: "#EC673D",
  lightColorSynsta: "#FCE5DE",
};

const reqired = "is required";

export const defaultValidationMessages = {
  firstName: `FirstName ${reqired}`,
  lastName: `LastName ${reqired}`,
  email: `Email ${reqired}`,
  profileId: `Profile ${reqired}`,
  userName: `Username ${reqired}`,
  phone: `Phonenumber ${reqired}`,
  role: `Role ${reqired}`,
  reportingTo: `This field ${reqired}`,
  permissions: `Permssions ${reqired}`,
  title: `Title ${reqired}`,
  category: `Category ${reqired}`,
  oldPassword: `Old password ${reqired}`,
  newPassword: `New password ${reqired}`,
  confirmPassword: `Confirm password ${reqired}`,
  users: `Users ${reqired}`,
  projects: `Projects ${reqired}`,
  organizationstructure: `This field ${reqired}`,
  reports: `This field ${reqired}`,
  meetings: `This field ${reqired}`,
  comunication: `This field ${reqired}`,
};

export const textFeildColor = {
  ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EC673D",
  },
  "&:hover": {
    backgroundColor: "#FCE5DE !important",
    ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderColor: "#EC673D",
    },
  },
};

export const textFeildSelect = {
  ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EC673D !important",
  },
};
export const muiSwitchColor={
  "& .MuiSwitch-switchBase.Mui-checked": {
          color: "#ec673d", // Change thumb color when checked
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#ec673d", // Change track color when checked
        },
        "& .MuiSwitch-switchBase": {
          color: "#f44336", // Thumb color when unchecked
        },
        "& .MuiSwitch-track": {
          backgroundColor: "#f44336", // Track color when unchecked
        },
}

export const getDomainExtension = (domain) => {
  if (!domain) return "";
  const parts = domain.split(".");
  return parts.length > 2
    ? parts.slice(-2).join(".")
    : parts.slice(-1).join(".");
};

export const getDomainWithoutExtension = (domain, domainExtension) => {
  if (!domain || !domainExtension) return domain;

  // Remove the dot prefix from the domainExtension if present
  const extensionWithoutDot = domainExtension.startsWith(".")
    ? domainExtension.slice(1)
    : domainExtension;

  // Remove the extension from the domain
  if (domain.endsWith(extensionWithoutDot)) {
    return domain.slice(0, -extensionWithoutDot.length - 1); 
  }

  return domain; 
};

export const textFieldSx={
  minWidth:"200px",
  "& .MuiInputBase-root.Mui-focused fieldset": {
    border: `1px solid ${defaultStyle.defaultColor}`,
  },
  "& .MuiInputBase-root:not(.Mui-focused) fieldset": {
    border: `1px solid ${defaultStyle.textFieldGreyBorder}`,
  },
}

export const InputPropsSx={
  backgroundColor: defaultStyle.whiteBackground,
                     color: defaultStyle.defaultColor,
                     height: "2.5rem",
                     fontSize: "14px",
                     padding: "4px 12px 4px 0",
                     "& .MuiList-root.MuiMenu-list": {
                       border: `1px solid ${defaultStyle.textFieldGreyBorder}`,
                     },
}