import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bpnmList: [],
  bpnmScreen: false,
  bpnmById:null
};

const bpnm = createSlice({
  name: "bpnm",
  initialState,
  reducers: {
    getBpnmScreen(state, action) {
      state.bpnmScreen = action.payload;
    },
    getBpnmList(state,action){
    state.bpnmList = action.payload
    },
    getBpnmById(state,action){
    state.bpnmById = action.payload
    }
  },
});

export default bpnm.reducer;
export const { getBpnmScreen,getBpnmList,getBpnmById } = bpnm.actions;
