export const refreshTokenQuery = {
  query: `mutation ($refreshToken: String!){
    refreshToken(
        refreshToken: $refreshToken 
       ) {
        token 
        expiresIn 
        refreshExpiresIn 
        tokenType 
        refreshToken 
        authorities
        pkgAuthorities
        attributes
   }
}`,
};

export const loginQuery = {
  query: `mutation ($username: String!, $password: String!, $sso: String, $sig: String) {login(username: $username password: $password sso: $sso sig:$sig) {
    token 
    expiresIn 
    refreshExpiresIn 
    tokenType 
    refreshToken 
    authorities
    redirectUrl
    pkgAuthorities
    attributes
  }
}`,
};

export const ssoRedirectUrlQuery = {
  query: `mutation {
    getSsoRedirectUrl(
        sso:"",
        sig:"",
        id:""
      ) 
  }`,
};

export const registerQuery = {
  query: `mutation ($request: RegisterRequest!) {
      register(
          request: $request
        ){
            id
        }
    }`,
};
export const createCustomerQuery = {
  query: `mutation ($request: CustomerCreateParams!) {
  createCustomer(
      request: $request
    ){
        id
        customerId
        customerName
    }
}`,
};
export const getCustomerQuery = {
  query: `query  {
  getCustomer{
        id
        customerId
        customerName
    }
}`,
};

export const checkDomainsQuery = (domainName) => ({
  query: `
    query CheckDomains {
      checkDomains(domains: "${domainName}") {
        domain
        available
        description
        isPremium
        premiumRegistrationPrice
        premiumRenewalPrice
        premiumRestorePrice
        iCannFee
        eapFee
      }
    }
  `,
});

export const getNameCheapPricingQuery = (domainExtension) => ({
  query: `
    query GetTldPricing {
    getTldPricing(tld: "${domainExtension}") {
        name
        minRegisterYears
        maxRegisterYears
        minRenewYears
        maxRenewYears
        renewalMinDays
        renewalMaxDays
        isEppRequired
        pricing {
            type
            prices {
                price
                additionalCost
                duration
            }
        }
    }
}


  `,
});

export const getTldsQuery = {
  query: `query GetNamecheapTlds {
    getNamecheapTlds {
        name
        minRegisterYears
        maxRegisterYears
        minRenewYears
        maxRenewYears
        renewalMinDays
        renewalMaxDays
        isEppRequired
        type
        subType
        isSupportsIDN
        tldState
        tld
    }
}

`,
};
export const createProfileQuery = {
  query: `mutation ($profile: ProfileCreateRequest!) {
  createProfile(
      request: $profile
    ){
        id
    }
}`,
};
export const getProfileQuery = {
  query: `query {
  profiles {
   id
   name
   permissionIds
  }
}`,
};
export const sendotpQuery = (email) => ({
  query: `
        query {
          userEmailExists(email: "${email}")
        }
      `,
});

export const generateOtpQuery = (email) => ({
  query: `
        mutation {
          generateOtp(email: "${email}")
        }
      `,
});

export const logoutQuery = {
  query: `mutation ($tokenId: ID!) {
    logoutUserByTokenId(
        tokenId : $tokenId
       ) {
           id
       }
}`,
};

export const emailExistsQuery = {
  query: `query ($email: String!) {
    userEmailExists(
        email: $email
      )
  }`,
};

export const completeResetPasswordQuery = {
  query: `mutation ($email: String!, $otp: String!, $newPassword: String!) {
    completeResetPassword(
        email: $email 
        otp: $otp 
        newPassword: $newPassword
       ) {
       id
   }
  }`,
};

export const changePasswordQuery = {
  query: `mutation ($username: String!, $oldPassword: String!, $newPassword: String!) {
    changePassword(
        username: $username 
        oldPassword: $oldPassword
        newPassword: $newPassword
      ) {
      id
  }
}`,
};

export const createTeamMemberQuery = {
  query: `mutation ($request: UserCreateRequest!) {
    createUser(
        request: $request
      ){
          id
      }
  }
  `,
};

export const getAlertsQuerry = (organizationId) => ({
  query: `
  query GetAlerts {
    getAlerts(organizationId: "${organizationId}, resolved: "false) {
        errorMessage
        resolvedBy
        resolved
        subscriptionId
        organizationId
        createdTime
        updatedTime
        uuid
    }
}
`,
});
export const resolveAlertQuerry = (uuid, resolvedBy) => ({
  query: `
  mutation ResolveAlert {
    resolveAlert(uuid: "${uuid}", resolvedBy: "${resolvedBy}")
}`,
});

export const getUserDomainsQuerry = (organisationId) => ({
  query: `
  query GetUserDomains {
    getUserDomains(organizationId: "${organisationId}") {
        domainName
        registered
        chargeAmount
        domainId
        orderId
        transactionId
        createdDate
        expiredDate
        years
        whoisGuardId
        whoisGuardExpiredDate
        lastPerformedAction
        whoisGuardEnabled
        forwardedToEmail
        subscriptionId
        registrarLockStatus
        autoRenew
        isPremium
    }
}
`,
});

export const updateTeamMemberQuery = (memberData) => ({
  query: `mutation {
    updateUser(request: 
      {
            id: "${memberData.id}",
             firstName: "${memberData.firstName}",
             lastName: "${memberData.lastName}",
             email: "${memberData.email}",
             mobile: "${memberData.mobile}",
             profileId: "${memberData.profileId}",
      })
    {
        id
    }
  }
  `,
});

export const getTeamMemberDetailsQuery = (id) => ({
  query: `query {
    userById(id: "${id}") {
      id
    username
    firstName
    lastName
    email
    mobile
    profile {
        id
        name
    } 
 }
}
  `,
});

export const socketQuery = {
  query: ` mutation  {
    authenticateWebSocket
}
  `,
};
export const createDomainQuery = (
  creatDoaminFormData,
  selectedCountryNameCode,
  selectedCountry,
  subscription_id,
  checkedWhoIsGuard,
  formattedExtendedAttributes
) => {
  console.log(
    "crreate DOmian FornmDtata insode querry mutation",
    creatDoaminFormData
  );
  console.log("222222 check who is insode querry mutation", checkedWhoIsGuard);
  console.log(
    " 88888sub id in crreate DOmian acgtion  in QWUeeerrrry ",
    subscription_id
  );
  console.log(
    "++++ extended attributescrreate DOmian acgtion  in QWUeeerrrry ",
    formattedExtendedAttributes
  );

  console.log(
    "++++++ phone insode querry mutation",
    selectedCountry + "." + creatDoaminFormData.phone
  );

  return {
    query: ` mutation CreateDomain {
    
      createDomain(
          request: {
              type: {
                  DomainName: "${creatDoaminFormData.DomainName}"
                  Years: ${creatDoaminFormData.Years}
                  PromotionCode: null
                  RegistrationOrganizationName: null
                  RegistrantJobTitle: null
                  RegistrantFirstName: "${creatDoaminFormData.RegistrantFirstName}"
                  RegistrantLastName: "${creatDoaminFormData.RegistrantLastName}"
                  RegistrantAddress1: "${creatDoaminFormData.RegistrantAddress1}"
                  RegistrantCity: "${creatDoaminFormData.RegistrantCity}"
                  RegistrantStateProvince: "${creatDoaminFormData.RegistrantStateProvince}"
                  RegistrantStateProvinceChoice: null
                  RegistrantPostalCode: "${creatDoaminFormData.RegistrantPostalCode}"
                  RegistrantCountry: "${selectedCountryNameCode}"
                  RegistrantPhone: "${selectedCountry + "." + creatDoaminFormData.phone}"
                  RegistrantPhoneExt: null
                  RegistrantFax: null
                  RegistrantEmailAddress: "${creatDoaminFormData.RegistrantEmailAddress}"
                  TechOrganizationName: null
                  TechJobTitle: null
                  TechFirstName: "${creatDoaminFormData.RegistrantFirstName}"
                  TechLastName: "${creatDoaminFormData.RegistrantLastName}"
                  TechAddress1: "${creatDoaminFormData.RegistrantAddress1}"
                  TechCity: "${creatDoaminFormData.RegistrantCity}"
                  TechStateProvince: "${creatDoaminFormData.RegistrantStateProvince}"
                  TechStateProvinceChoice: null
                  TechPostalCode: "${creatDoaminFormData.RegistrantPostalCode}"
                  TechCountry: "${selectedCountryNameCode}"
                  TechPhone: "${selectedCountry + "." + creatDoaminFormData.phone}"
                  TechPhoneExt: null
                  TechFax: null
                  TechEmailAddress: "${creatDoaminFormData.RegistrantEmailAddress}"
                  AdminOrganizationName: null
                  AdminJobTitle:null
                  AdminFirstName:" ${creatDoaminFormData.RegistrantFirstName}"
                  AdminLastName: "${creatDoaminFormData.RegistrantLastName}"
                  AdminAddress1: "${creatDoaminFormData.RegistrantAddress1}"
                  AdminAddress2: null
                  AdminCity: "${creatDoaminFormData.RegistrantCity}"
                  AdminStateProvince: "${creatDoaminFormData.RegistrantStateProvince}"
                  AdminStateProvinceChoice: null
                  AdminPostalCode: "${creatDoaminFormData.RegistrantPostalCode}"
                  AdminCountry: "${selectedCountryNameCode}"
                  AdminPhone: "${selectedCountry + "." + creatDoaminFormData.phone}"
                  AdminPhoneExt: null
                  AdminFax: null
                  AdminEmailAddress: "${creatDoaminFormData.RegistrantEmailAddress}"
                  AuxBillingOrganizationName:null
                  AuxBillingJobTitle: null
                  AuxBillingFirstName:"${creatDoaminFormData.RegistrantFirstName}"
                  AuxBillingLastName: "${creatDoaminFormData.RegistrantLastName}"
                  AuxBillingAddress1: "${creatDoaminFormData.RegistrantAddress1}"
                  AuxBillingAddress2: null
                  AuxBillingCity: "${creatDoaminFormData.RegistrantCity}"
                  AuxBillingStateProvince: "${creatDoaminFormData.RegistrantStateProvince}"
                  AuxBillingStateProvinceChoice: null
                  AuxBillingPostalCode: "${creatDoaminFormData.RegistrantPostalCode}"
                  AuxBillingCountry: "${selectedCountryNameCode}"
                  AuxBillingPhone: "${selectedCountry + "." + creatDoaminFormData.phone}"
                  AuxBillingPhoneExt: null
                  AuxBillingFax: null
                  AuxBillingEmailAddress: "${creatDoaminFormData.RegistrantEmailAddress}"
                  BillingFirstName: "${creatDoaminFormData.RegistrantFirstName}"
                  BillingLastName: "${creatDoaminFormData.RegistrantLastName}"
                  BillingAddress1: "${creatDoaminFormData.RegistrantAddress1}"
                  BillingAddress2: null
                  BillingCity: "${creatDoaminFormData.RegistrantCity}"
                  BillingStateProvince: "${creatDoaminFormData.RegistrantStateProvince}"
                  BillingStateProvinceChoice: null
                  BillingPostalCode: "${creatDoaminFormData.RegistrantPostalCode}"
                  BillingCountry: "${selectedCountryNameCode}"
                  BillingPhone: "${selectedCountry + "." + creatDoaminFormData.phone}"
                  BillingPhoneExt: null
                  BillingFax: null
                  BillingEmailAddress: "${creatDoaminFormData.RegistrantEmailAddress}"
                  IdnCode: null
                  PremiumPrice: null
                  EapFee: null
                  ExtendedAttributes:[${formattedExtendedAttributes}]
                  IsPremiumDomain: null
                  AddFreeWhoisguard: "${checkedWhoIsGuard}"
              WGEnabled: "${checkedWhoIsGuard}"
              }
              subscriptionId: "${subscription_id}"
              organizationId: "f061cfc6-c4bc-4b76-a9e8-f446dcbd89bf"
              tld: "in"
          }
      ) {
          domainName
          registered
          chargeAmount
          domainId
          orderId
          transactionId
          createdDate
          expiredDate
          years
          whoisGuardId
        whoisGuardExpiredDate
        lastPerformedAction
      }
  }
  
    `,
  };
};

export const renewDomainQuerry = (
  subscription_id,
  organisationId,
  domainName,
  numberOfYears,
  isPremiumDomain,
  premiumPrice
) => ({
  query: `
  mutation RenewDomain {
    renewDomain(
        request: {
            subscriptionId: "${subscription_id}"
            organizationId: "${organisationId}"
            type: {
                domainName: "${domainName}"
                years: "${numberOfYears}"
                isPremiumDomain: "${isPremiumDomain}"
                premiumPrice: "${premiumPrice}"
            }
        }
    ) {
        domainName
        registered
        chargeAmount
        domainId
        orderId
        transactionId
        createdDate
        expiredDate
        years
    }
}`,
});

export const reactivateDomainQuerry = (
  subscription_id,
  organisationId,
  domainName,
  numberOfYears,
  isPremiumDomain,
  premiumPrice
) => ({
  query: `
  mutation ReactivateDomain {
    reactivateDomain(
        request: {
            subscriptionId: "${subscription_id}"
            organizationId: "${organisationId}"
            type: {
                domainName: "${domainName}"
                years: "${numberOfYears}"
                isPremiumDomain: "${isPremiumDomain}"
                premiumPrice: "${premiumPrice}"
            }
        }
    ) {
        domainName
        registered
        chargeAmount
        domainId
        orderId
        transactionId
        createdDate
        expiredDate
        years
    }
}`,
});

export const ToggleDomainPrivacyQuerry = (
  forwardedToEmail,
  toggle,
  whoisGuardID
) => ({
  query: `
  mutation ToggleDomainPrivacy {
    toggleDomainPrivacy(
        request: { 
        forwardedToEmail: "${forwardedToEmail}", 
        toggle: ${toggle},
         whoisGuardId: "${whoisGuardID}" 
         }
    ) {
        domainName
        registered
        chargeAmount
        domainId
        orderId
        transactionId
        createdDate
        expiredDate
        years
        whoisGuardId
        whoisGuardExpiredDate
        lastPerformedAction
        whoisGuardEnabled
        forwardedToEmail
    }
}`,
});

export const getDNSHostsQuery = (domainName) => ({
  query: `
      query GetDNSHosts {
    getDNSHosts(domain: "${domainName}") {
        hostId
        name
        mxPref
        ttl
        domain
        type
        friendlyName
        isDDNSEnabled
        isActive
        associatedAppTitle
        uuid
    }
}

    `,
});

export const getNameServersQuery = (domainName) => ({
  query: `
      query GetNameServers {
    getNameServers(domain: "${domainName}") {
        domain
        nameServer
        ip
        uuid
    }
}

    `,
});

export const setDefaultDNSQuerry = (sld, tld) => ({
  query: `
    mutation SetDefaultDNS {
    setDefaultDNS(sld: "${sld}", tld: "${tld}") {
        hostId
        name
        mxPref
        ttl
        domain
        type
        friendlyName
        isDDNSEnabled
        isActive
        associatedAppTitle
        uuid
    }
}
`,
});

export const setCustomDNSQuerry = (sld, tld, nameservers) => ({
  query: `
   mutation SetCustomDNS {
    setCustomDNS(sld: "${sld}", tld: "${tld}", nameservers: "${nameservers}") {
        hostId
        name
        mxPref
        ttl
        domain
        type
        friendlyName
        isDDNSEnabled
        isActive
        associatedAppTitle
        uuid
    }
}

`,
});

export const createNameServerQuerry = (sld, tld, nameservers, ip) => ({
  query: `
   mutation CreateNameServer {
    createNameServer(sld: "${sld}", tld: "${tld}", nameserver: "${nameservers}", ip: "${ip}") {
        domain
        nameServer
        ip
        uuid
    }
}


`,
});

export const updateNameServerQuerry = (sld, tld, nameservers, oldIp, ip) => ({
  query: `
   mutation UpdateNameServer {
    updateNameServer(sld: "${sld}", tld: "${tld}", nameserver: "${nameservers}", oldIp: "${oldIp}", ip: "${ip}") {
        domain
        nameServer
        ip
        uuid
    }
}

`,
});

export const deleteNameServerQuerry = (sld, tld, nameservers) => ({
  query: `
  mutation DeleteNameServer {
    deleteNameServer(sld: "${sld}", tld: "${tld}", nameserver: "${nameservers}")
}

`,
});

export const setHostsQuerry = (sld, tld, emailType, detailList) => ({
  query: `
  mutation SetHosts {
    setHosts(sld: "${sld}", tld: "${tld}", emailType: "${emailType}", detailList: ${detailList}) {
        hostId
        name
        mxPref
        ttl
        domain
        type
        friendlyName
        isDDNSEnabled
        isActive
        associatedAppTitle
        uuid
    }
}


`,
});

export const setRegistrarStatusLockQuerry = (domainName, lock) => ({
  query: `
 mutation SetRegistrarStatusLock {
    setRegistrarStatusLock(domain: "${domainName}", lock: ${lock}) {
        domainName
        registered
        chargeAmount
        domainId
        orderId
        transactionId
        createdDate
        expiredDate
        years
        whoisGuardId
        whoisGuardExpiredDate
        lastPerformedAction
        whoisGuardEnabled
        forwardedToEmail
        subscriptionId
        registrarLockStatus
        autoRenew
    }
}


`,
});
